<template>

 <div class="inner-pages hd-white">
    <div id="wrapper">
      <img :src="require('../assets/images/Vencasa-Leaf-Top-Border-4K.webp')" />
      <div id="login">
        <div class="login">
          <h2 style="font-size:19px;">Recover Password</h2>
          <form @submit.prevent="" autocomplete="off">
            <div style="padding-top:3px" class="form-group">
              <label>New Password </label>
              <input
                v-model="password"
                style="height: 50px"
                class="form-control"
                type="password"
              />
              <i class="ti-user"></i>
               <p class="error" v-if="$v.password.$dirty && !$v.password.required">
      Password is required!
    </p>
            </div>
             <div class="form-group">
              <label>Confirm Password</label>
              <input
                v-model="confirmPassword"
                style="height: 50px"
                class="form-control"
                type="password"
              />
              <i class="ti-user"></i>
              <p class="error" v-if="!$v.confirmPassword.sameAsPassword  && clicked">
      Passwords must match!
    </p>
            </div>

            <div id="pass-info" class="clearfix"></div>
            <button
              @click="submit"
              style="outline: none; color: white; width: 200px; float: left"
              class="btn_1 rounded full-width add_top_30 res-button-how"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
  </div>
 </div>
</template>

<script>
import { validationMixin } from "vuelidate";
const { required, sameAs } = require("vuelidate/lib/validators");

export default {
  data() {
    return {
      clicked: false,
      password: "",
      confirmPassword: "",
    };
  },
  validations: {
    password: {
      required,
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  watch:{
    confirmPassword(){
        this.clicked = false
    }
  },
  mixins: [validationMixin],
  methods: {
    submit() {
      this.clicked = true
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          resetToken: this.$route.query.token,
          password: this.password,
          password_confirmation: this.confirmPassword,
        };
        this.$store.dispatch("resetPassword", data).then((res) => {
          if (res == true) {
            this.$store.commit(
              "setMessage",
              "Password reset successfully!"
            );
            this.$router.push("/login");
          } else
            this.$store.commit(
              "setMessage",
              "Password reset error!"
            );
        });
      }
    },
  },
};
</script>

<style scoped src="../assets/css/styles.css">
</style>